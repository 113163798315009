/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// global.scss 
// swiper bundle styles
@import '~swiper/swiper-bundle.min.css';
// swiper core styles
@import '~swiper/swiper.min.css';
@import '~@ionic/angular/css/ionic-swiper.css';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import url(../node_modules/@bh-digitalsolutions/ui-toolkit/dist/bh-ui-toolkit/bh-ui-toolkit.css);

//Import the Font Into the Angular Project
@font-face {
    font-family: 'Noto Sans';
    src: url('assets/font/NotoSans-Regular.ttf')
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/font/Poppins-Regular.ttf');
}

@media (min-width: 500px) {
    .main-container-report {
        width: 412px;
        height: 100%;
        position: relative;
        margin: 0 auto;
    }

    ion-modal.modal-sheet {
        --width: 450px;
    }

    ion-modal.cropper-modal {
        --width: 450px;
    }
}

/**  ion-toolbar  ****************************************************************/


.header-md::after {
    /* Removes the toolbar shadow. */
    background: none;
}


/**  ion-title  ****************************************************************/


ion-title {
    font-size: 14px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
}


/**  ion-content  ****************************************************************/


// ion-content {
//     --overflow: hidden;
//     overflow: auto;
//     font-family: 'Poppins';
//     font-style: normal;
//     font-size: 14px;

//     &::-webkit-scrollbar {
//         display: none;
//     }
// }


/**  hide scrollbar but allow scrolling  ****************************************************************/

// .div-scroll div {
//     -ms-overflow-style: none;
//     /* for Internet Explorer, Edge */
//     scrollbar-width: none;
//     /* for Firefox */
//     overflow-y: scroll;
// }

// .div-scroll div::-webkit-scrollbar {
//     display: none;
//     /* for Chrome, Safari, and Opera */
// }


/**  ng-select  ****************************************************************/

.create-dull .ng-select.ng-select-single .ng-select-container {
    height: 44px;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: #ccc;
    box-shadow: unset;
}


/**  ion-alert  ****************************************************************/


.sc-ion-alert-md-h {
    --max-width: 325px;
}

.alert-button.sc-ion-alert-ios {
    color: black;
}

.alert-head.sc-ion-alert-ios {
    text-align: left;
}

.alert-message.sc-ion-alert-ios {
    text-align: left;
}

/**  ion-icon  ****************************************************************/


.icon-ion {
    width: 30px;
    height: 30px;
}


/**  ion-accordion-group  ****************************************************************/


ion-accordion.accordion-expanded>[slot=header] .ion-accordion-toggle-icon {
    transform: none;
}

ion-accordion.accordion-animated>[slot=header] .ion-accordion-toggle-icon {
    transition: none;
}

.accordion-collapsed .plus-icon {
    display: revert;
}

.accordion-collapsed .minus-icon {
    display: none;
}

.accordion-collapsing .plus-icon {
    display: revert;
}

.accordion-collapsing .minus-icon {
    display: none;
}

.accordion-expanding .plus-icon {
    display: none;
}

.accordion-expanding .minus-icon {
    display: none;
}

.accordion-expanded .plus-icon {
    display: none;
}

.accordion-expanded .minus-icon {
    display: revert;
}


/**  ion-popover  ****************************************************************/


ion-popover {
    --width: 180px;
}


/**  ion-toast  ****************************************************************/


ion-toast {
    --background: #C6F7E2;
    color: black;
}


/**  red border validation  ****************************************************************/


.ng-invalid:not(form) {
    border: 1px solid #E16E75;
    border-radius: 4px;
}


.color-black {
    color: black;
}

.color-white {
    color: white;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-to-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 40%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.report-content {
    --background: #EFF0F2;
}

.report-title {
    padding-inline: 10px;
    color: #121212;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.25px;
    background: #EFF0F2;
}


.alert-wrapper .alert-head .alert-title {
    color: #121212;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.25px;
}

.alert-wrapper .alert-message {
    color: #595959;
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

button.alert-button.alert-button-confirm {
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-transform: capitalize;
}

button.alert-button.button-confirm-color {
    background-color: #02A783;
}

button.alert-button.button-delete-color {
    background-color: #E12D39;
}


button.alert-button.alert-button-cancel {
    color: #121212;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-transform: capitalize;
}


.center-align {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

ion-modal ion-content::part(scroll) {
    overflow-y: var(--overflow);
    overscroll-behavior-y: contain;
}

ion-modal ion-content::part(scroll)::-webkit-scrollbar {
    display: none;
}

ion-modal.auto-height ion-content::part(scroll) {
    --width: 100%;
    --height: auto;
}

ion-modal.auto-height ion-content::part(scroll) {
    bottom: 0;
    position: absolute;
}

ion-modal.auto-height ion-content::part(scroll) {
    position: relative !important;
    contain: content !important;
}

ion-modal.modal-sheet ion-content::part(scroll) {
    max-height: 95vh;
}

.overlay-swiper-action-btns ion-button {
    position: absolute;
    bottom: 20px;
    width: 40px;
    height: 40px;
    z-index: 10;
    --padding-start: 10px;
    --padding-end: 10px;
}

#btn-crop {
    left: 15px;
}

#btn-crop::part(native) {
    border-radius: 30px;
    background-color: white;
}

#btn-bounding-box {
    right: 15px;
}

.btn-bounding-box::part(native) {
    border-radius: 30px;
    background-color: white;
}

.btn-bounding-box-active::part(native) {
    border-radius: 30px;
    background-color: #65D6AD;
}

// ion-modal.modal-sheet .ion-page {
//     margin-top: 10px;
// }

.col-padding {
    padding-top: 0px;
    padding-bottom: 0px;
}

.row-line-height {
    line-height: 1.7;
}


ion-segment {
    background: white;
    justify-content: left;
    margin: 6px 6px 6px 10px;
    border-radius: 0px;
}

ion-segment-button {
    height: 37px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.25px;
    color: #595959;
    flex: none;
    order: 1;
    flex-grow: 0;
    text-transform: capitalize;
    min-width: 0px;
    min-height: 0px;
}

.refresher ion-item {
    --padding-start: 8px;
}

.refresher ion-icon {
    font-size: 12px;
    align-self: start;
    margin: 15px 8px;
}

.back-arrow {
    width: 30px;
    height: 30px;
    color: black;
}

a {
    cursor: pointer;
}